<template>
  <div class="Register">
    <el-row>
      <el-col :xs="{span: 22, offset: 1}" :sm="{span: 12, offset: 6}" :md="{span: 8, offset: 8}" :lg="{span: 6, offset: 9}" :xl="{span: 4, offset: 10}">
        <h3 class="title">
          <router-link :to="{ name: 'Index' }">
            {{ config.name }}
          </router-link>
        </h3>
        <h4 class="desc">注册您的账号</h4>
        <transition name="el-fade-in" mode="out-in">
          <el-form ref="registerForm" status-icon :model="registerForms" :rules="registerRules">
            <el-form-item label="用户名" prop="username">
              <el-input placeholder="请输入用户名" clearable v-model="registerForms.username" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="password">
              <el-input placeholder="请输入登录密码" show-password v-model="registerForms.password" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <el-input placeholder="请输入手机号" clearable v-model="registerForms.mobile" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-input placeholder="请输入验证码" v-model="registerForms.captcha" auto-complete="off">
                <template #append>
                  <el-button @click="handleSms" :loading="sendSms">获取验证码</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input placeholder="请输入邮箱（选填）" clearable v-model="registerForms.email" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="registerForms.checked">
                阅读并同意<a class="user-rule" href="/page/eula" target="_blank">《用户协议》</a>
              </el-checkbox>
            </el-form-item>
            <el-form-item class="button-group">
              <el-button type="primary" @click="handleRegister">提交</el-button>
              <el-button @click="toLogin">
                返回登录
              </el-button>
            </el-form-item>
          </el-form>
        </transition>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { registerRules } from './rules/register-rules'
import { IResponse } from '../common/http'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

interface IRegisterForms {
  username: string;
  password: string;
  mobile: string;
  captcha: string;
  email: string;
  checked: boolean;
}

declare let globalConfig:any

export default defineComponent({
  name: 'Register',
  setup () {
    const { proxy } : any = getCurrentInstance()
    const config = globalConfig
    const sendSms = ref(false)
    const router = useRouter()

    // 注册表单字段
    const registerForms = ref<IRegisterForms>({
      username: '',
      password: '',
      mobile: '',
      captcha: '',
      email: '',
      checked: false
    })

    const toLogin = (): void => {
      router.push({ name: 'Login' })
    }

    // 登录操作
    const handleRegister = (): void => {
      proxy.$refs.registerForm.validate((valid: boolean) => {
        if (valid) {
          if (!registerForms.value.checked) {
            ElMessage.warning({
              message: '请阅读并同意用户协议',
              type: 'warning'
            })
            return
          }
          proxy.$axios.post('/user/register', registerForms.value)
            .then((res: IResponse) => {
              if (res.code === 1) {
                ElMessage.success({
                  message: res.msg,
                  type: 'success'
                })
                router.push({ name: 'Login' })
              } else {
                ElMessage.warning({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = registerForms.value.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: mobile,
          event: 'register'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    return {
      registerForms,
      handleRegister,
      registerRules,
      handleSms,
      sendSms,
      toLogin,
      config
    }
  }
})
</script>

<style lang="scss" scoped>
  .Register {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #409EFF;
    .user-rule {
      &:hover {
        text-decoration: underline;
      }
    }
    .el-row {
      width: 100%;
    }
    .el-col {
      max-width: 360px;
      min-width: 240px;
      margin: 0 auto;
      padding: 30px 24px;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(16,16,17,.02), 0 3.4px 8px rgba(16,16,17,.007), 0 12px 30px rgba(16,16,17,.003);
      animation: fade-in 0.5s;
      background: rgba(255, 255, 255);
      .title {
        color: #303133;
        line-height: 1.8;
      }
      .desc {
        color: #303133;
        font-weight: 400;
        line-height: 2.4;
      }
    }

    :deep(.el-checkbox__inner:hover) {
      border-color: #67C23A;
    }

    :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
      border-color: #67C23A;
    }

    :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
      background-color: #67C23A;
      border-color: #67C23A;
    }

    :deep(.el-checkbox__input.is-checked+.el-checkbox__label) {
      color: #333;
    }

    :deep(.el-checkbox__label) {
      font-weight: bold;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 320px) {
    .Register {
      .el-button {
        display: block;
        width: 100%;
      }
      .button-group {
        display: flex;
        flex-direction: column;
        .el-button {
          margin-top: 10px;
        }
      }
      .el-button+.el-button {
        margin-left: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-height: 800px) {
    .Register {
      height: 100%;
      display: block;
      padding: 20px 0;
    }
  }
</style>
