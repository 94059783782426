/*
*   拦截规则
*   @author MrKENTG
*/

// 自定义拦截用户名规则
export const validateUsername = (rule: Record<string, unknown>, value: string, callback: (arg0: Error | undefined) => void): void => {
  if (value === '') {
    callback(new Error('用户名不能为空'))
  } else {
    callback(undefined)
  }
}

// 自定义拦截昵称规则
export const validateNickname = (rule: Record<string, unknown>, value: string, callback: (arg0: Error | undefined) => void): void => {
  if (value === '') {
    callback(new Error('昵称不能为空'))
  } else {
    callback(undefined)
  }
}

// 自定义拦截密码规则
export const validatePassword = (rule: Record<string, unknown>, value: string, callback: (arg0: Error | undefined) => void): void => {
  if (value === '') {
    callback(new Error('密码不能为空'))
  } else {
    callback(undefined)
  }
}

// 自定义拦截手机号规则
export const validateMobile = (rule: Record<string, unknown>, value: string, callback: (arg0: Error | undefined) => void): void => {
  if (value === '') {
    callback(new Error('手机号不能为空'))
  } else {
    callback(undefined)
  }
}

// 自定义拦截验证码规则
export const validateCaptcha = (rule: Record<string, unknown>, value: string, callback: (arg0: Error | undefined) => void): void => {
  if (value === '') {
    callback(new Error('验证码不能为空'))
  } else {
    callback(undefined)
  }
}

export const validateBio = (rule: Record<string, unknown>, value: string, callback: (arg0: Error | undefined) => void): void => {
  if (value === '') {
    callback(new Error('个人简介不能为空'))
  } else {
    callback(undefined)
  }
}
