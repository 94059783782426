import { ref } from 'vue'
import { validateCaptcha, validateMobile, validatePassword, validateUsername } from './rules'

// 拦截注册规则接口
interface IRegisterRules {
  username: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  password: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  mobile: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  captcha: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  email: {
    type: string;
    trigger: string;
    message: string;
  }[];
}

// 拦截注册规则
export const registerRules = ref<IRegisterRules>({
  username: [
    {
      min: 2,
      max: 30,
      message: '用户名长度在2到30个字符',
      trigger: 'blur'
    },
    {
      validator: validateUsername,
      trigger: 'blur'
    }
  ],
  password: [
    {
      min: 6,
      max: 30,
      message: '密码长度必须在6到30个字符',
      trigger: 'blur'
    },
    {
      validator: validatePassword,
      trigger: 'blur'
    }
  ],
  mobile: [
    {
      min: 11,
      max: 11,
      message: '只支持11位手机号',
      trigger: 'blur'
    },
    {
      validator: validateMobile,
      trigger: 'blur'
    }
  ],
  captcha: [
    {
      min: 4,
      max: 8,
      message: '验证码长度必须在4到8个字符',
      trigger: 'blur'
    },
    {
      validator: validateCaptcha,
      trigger: 'blur'
    }
  ],
  email: [
    {
      type: 'email',
      trigger: 'blur',
      message: '邮箱格式不正确'
    }
  ]
})
