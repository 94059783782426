
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { registerRules } from './rules/register-rules'
import { IResponse } from '../common/http'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

interface IRegisterForms {
  username: string;
  password: string;
  mobile: string;
  captcha: string;
  email: string;
  checked: boolean;
}

declare let globalConfig:any

export default defineComponent({
  name: 'Register',
  setup () {
    const { proxy } : any = getCurrentInstance()
    const config = globalConfig
    const sendSms = ref(false)
    const router = useRouter()

    // 注册表单字段
    const registerForms = ref<IRegisterForms>({
      username: '',
      password: '',
      mobile: '',
      captcha: '',
      email: '',
      checked: false
    })

    const toLogin = (): void => {
      router.push({ name: 'Login' })
    }

    // 登录操作
    const handleRegister = (): void => {
      proxy.$refs.registerForm.validate((valid: boolean) => {
        if (valid) {
          if (!registerForms.value.checked) {
            ElMessage.warning({
              message: '请阅读并同意用户协议',
              type: 'warning'
            })
            return
          }
          proxy.$axios.post('/user/register', registerForms.value)
            .then((res: IResponse) => {
              if (res.code === 1) {
                ElMessage.success({
                  message: res.msg,
                  type: 'success'
                })
                router.push({ name: 'Login' })
              } else {
                ElMessage.warning({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = registerForms.value.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: mobile,
          event: 'register'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    return {
      registerForms,
      handleRegister,
      registerRules,
      handleSms,
      sendSms,
      toLogin,
      config
    }
  }
})
